<template>
  <div class="procedure-form-view">
    <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
    <app-header icon="procedures" :title="pageTitle" goes-back></app-header>

    <section>
      <div class="card">
        <div class="card-content">
          <form @submit.prevent="onSubmit">
            <div class="row">
              <div class="col-3">
                <app-input
                  label="Nome"
                  v-model="procedure.name"
                  type="text"
                  :errors="errors.name"
                  is-required
                ></app-input>
              </div>
              <div class="col-3">
                <app-input
                  label="Valor"
                  v-model="procedure.value"
                  type="tel"
                  :errors="errors.value"
                  :mask="MASKS.money"
                  is-required
                ></app-input>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12 has-text-right">
                <b-button
                  class="button-rounded remove-focus btn-success border-0 px-3"
                  @click="onSubmit()"
                  :loading="isSaving"
                  :disabled="isLoading || isSaving"
                >
                  Salvar
                </b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ProceduresService from '@/services/procedures.service';
import MASKS from '@/constants/masks.constant';

export default {
  data: () => ({
    MASKS: MASKS,
    isLoading: false,
    isSaving: false,
    procedureId: null,
    procedure: {},
    errors: {},
  }),
  computed: {
    pageTitle() {
      return `${this.procedureId ? 'Editar' : 'Cadastrar'} Procedimento`;
    },
  },
  methods: {
    onSubmit() {
      this.isSaving = true;
      this.errors = {};

      const { name, value } = this.procedure;
      const data = {
        name,
        value: this.unmask(value),
      };

      const promise = this.procedureId
        ? ProceduresService.update(this.procedureId, data)
        : ProceduresService.store(data);

      promise
        .then(() => {
          this.$buefy.snackbar.open('Procedimento salvo com sucesso.');
          this.$router.go(-1);
        })
        .catch(({ response }) => {
          this.isSaving = false;
          const { status, data } = response;

          if (status === 422) this.errors = data.errors;
          else
            this.$buefy.snackbar.open(
              data.message || 'Procedimento salvo com sucesso.'
            );
        });
    },
    loadProcedure(id) {
      this.isLoading = true;

      ProceduresService.getId(id)
        .then(({ data }) => {
          this.procedure = {
            ...data,
            value: data.value.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
          };
        })
        .catch(() =>
          this.$buefy.snackbar.open('Erro ao carregar procedimento.')
        )
        .finally(() => (this.isLoading = false));
    },
    unmask(value) {
      if (!value) return value;

      const realValue = value.split('R$').pop();
      return realValue.trim().replaceAll('.', '').replace(',', '.');
    },
  },
  mounted() {
    this.procedureId = Number(this.$route.params.id);
    this.procedureId && this.loadProcedure(this.procedureId);
  },
};
</script>
